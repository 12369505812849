<template>
    <div class="page">
        <div class="page-header pd">
            <div>
                <sc-button icon="plus" @click="$router.push('player-metadata/new')" v-if="$user.isAtLeast(1)">Add
                    {{ resource.name }}
                </sc-button>
            </div>
            <!--            <sc-search :table="table"></sc-search>-->
        </div>
        <sc-table :table="table" v-if="table">

            <template #column-edit="{row}">
                <sc-table-cell :table="table" field="edit" :padded="false" :clickable="false">
                    <sc-table-button @click="edit(row)" icon="edit">
                    </sc-table-button>
                </sc-table-cell>
            </template>

            <template #column-delete="{row}">
                <sc-table-cell :table="table" field="delete" :padded="false" :clickable="false">
                    <sc-table-button-delete @destroy="destroy(row)">
                        Do you wish to delete the {{ resource.name }} "{{ row.attributes.name }}"?
                    </sc-table-button-delete>
                </sc-table-cell>
            </template>
        </sc-table>
    </div>
</template>

<script>
import ScSearch from "@/components/sc-search";

export default {
  name: "all",
  components: {ScSearch},

  data() {
    return {
      table: null
    }
  },

  props: {
    passed: {
      required: true,
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.table = this.$stable.createTable({
      name: `List of ${this.resource.plural}`,
      url: this.resource.api,
      sortBy: 'name',
      columns: {
        name: {
          fill: true,
          target: 'attributes.name'
        },
        datatype: {
          fill: true,
          target: 'attributes.datatype'
        },
        edit: {width: 70, sort: false, guard: !this.$user.isAtLeast(1)},
        delete: {width: 80, sort: false, guard: !this.$user.isAtLeast(1)}
      },
      hover: false,
    });
    this.table.fetch();
  },

  methods: {
    search() {
      this.table.fetch();
    },

    destroy(row) {
      this.$talker.api.delete(`${this.resource.api}/${row.id}`)
        .then(res => {
          this.table.fetch();
          this.$notify.success(`${row.attributes.name} deleted`)
        })
    },

    edit(row) {
      this.$router.push(`${this.resource.route}/${row.id}/edit`)
    }
  }
}
</script>

<style scoped lang="scss">
.btns {
    text-align: center;
    padding: 2em 0 0.5em;
}
</style>